/* This will hide the background colors when printing */
@media print {
    .print-friendly .MuiTableRow-root {
        background-color: transparent !important;
    }
    
    /* Hide picking colors section when printing */
    .print-friendly .picking-colors {
        display: none;
    }
    
    /* Set A4 portrait aspect ratio with specific margins */
    @page {
        size: A4 portrait; /* Set the page size to A4 portrait */
        margin: 5mm; /* Set margins (you can adjust this for wider/narrower borders) */
    }

    /* Ensure the printed content respects the margins */
    body {
        margin: 0;
        padding: 0;
    }

    /* Optional padding for the printed content */
    .print-friendly {
        padding: 1mm; /* Add padding inside the printed area */
        font-size: 12px;
        color: black;
    }

    /* Hide dialog and background when printing */
    .MuiDialog-root {
        display: none;
    }

    /* Make sure buttons are hidden in the print version */
    button, .MuiDialogActions-root {
        display: none;
    }

    /* Ensure the table has proper spacing when printing */
    .MuiTableCell-root {
        padding: 8px !important;
        border-bottom: 1px solid black !important;
    }
}
