/* Existing Styles */
.App {
  text-align: center;
}

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
}

main {
  padding-top: 64px; /* Ensure main content starts below the AppBar */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* Hero Section Styles */
.hero-container {
  position: relative;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
}

/* Grid Item Styles */
.grid-item-paper {
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px; /* Add bottom margin for spacing in mobile view */
}

@media (min-width: 600px) {
  .grid-item-paper {
    margin-bottom: 0; /* Remove margin in desktop view */
  }
}

@media (max-width: 600px) {
  .grid-item-paper {
    padding: 8px; /* Reduce padding for mobile */
    margin-bottom: 12px; /* Ensure spacing between items */
  }

  .hero-content {
    padding: 10px; /* Adjust padding for better fit in mobile */
  }
}

/* Example Custom Button */
.custom-button {
  margin-top: 32px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
  box-sizing: border-box;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* New Styles for Filters Component */
.filters-toolbar {
  background-color: #0C0A5A;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  color: white;
}

.search-sort-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 20px;
  margin-bottom: 20px;
}

.search-sort-container-mobile {
  flex-direction: column;
  gap: 10px;
}

.filter-button {
  text-transform: none;
}

.white-textfield .MuiOutlinedInput-root {
  background-color: white;
  color: black;
}

.white-textfield .MuiInputLabel-root {
  color: black;
}

.white-textfield .MuiOutlinedInput-notchedOutline {
  border-color: black;
}

.white-formcontrol .MuiOutlinedInput-root {
  background-color: white;
  color: black;
}

.white-formcontrol .MuiInputLabel-root {
  color: black;
}

.white-formcontrol .MuiOutlinedInput-notchedOutline {
  border-color: black;
}

/* ToggleButtonGroup Styles */
.toggle-button-group .MuiToggleButton-root {
  background-color: rgb(148, 1, 1);
  color: red;
  border: 2px solid gold;
}

.toggle-button-group .MuiToggleButton-root.Mui-selected {
  background-color: #0C0A5A; /* Dark blue */
  color: white;
  border: 2px solid gold; /* Gold border when selected */
}

.toggle-button-group .MuiToggleButton-root:hover {
  background-color: rgb(148, 1, 1); /* Hover color */
}

.toggle-button-group .MuiToggleButton-root.Mui-selected:hover {
  background-color: #0C0A5A /* Hover color when selected */
}

.filter-paper {
  padding: 16px;
  margin-bottom: 16px;
}

.filter-close-button {
  width: 100%;
  margin-top: 16px;
}

.app-bar {
  width: 100%;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}

.toolbar .left-section {
  display: flex;
  align-items: center;
}

.toolbar .right-section {
  display: flex;
  align-items: right;
}

.toolbar .left-section {
  flex: 1;
}

.toolbar .right-section {
  flex: 1;
  justify-content: flex-end;
}

@media (max-width: 600px) {
  .toolbar .right-section {
    flex-basis: 100%;
    justify-content: center;
  }

  .toolbar .right-section {
    flex-basis: 100%;
    justify-content: right;
  }
}

/* Ensure the Select component doesn't exceed the viewport width */
.currency-select {
  max-width: 100px;
}

/* Scroll Lock Styles */
body.block-body-scroll {
  overflow: hidden;
  width: 100%;
}

body.block-body-scroll.ios {
  position: fixed;
  width: 100%;
}
